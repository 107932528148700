.app {
  display: grid;
  grid-template-columns: 20% auto;
  align-items: start;
}

.header {
  display: grid;
  grid-template-columns: auto;
}

.body {
  display: grid;
  grid-template-columns: auto;
}

.bodyContent {
  display: grid;
}
